import Isotope from "isotope-layout";

// flatten object by concatting values
function concatValues(obj) {
  let value = "";
  for (const prop in obj) {
    value += obj[prop];
  }
  return value;
}

jQuery(document).ready(function ($) {
  if ($("body").hasClass("archive")) {
    // init Isotope
    var isoContainer = $(".product-list .row");

    var sortProducts = new Isotope(isoContainer[0], {
      itemSelector: ".product",
      layoutMode: "fitRows",
    });

    // store filter for each group
    var filters = {};
    var filterButtonsParent = $(".product-filters-ul");
    var filterDropDownParent = $(".products-filter-dropdown");

    filterButtonsParent.on("click", ".filter-option", function (event) {
      event.preventDefault();

      var $this = $(event.currentTarget);

      // get group key
      var $buttonGroup = $this.parents(".product-filters-ul");
      var filterGroup = $buttonGroup.attr("data-filter-group");

      // set filter for group
      filters[filterGroup] = $this.attr("data-filter");

      // combine filters
      var filterValue = concatValues(filters);

      // set filter for Isotope
      // isoContainer.isotope({
      //   filter: filterValue
      // })
      sortProducts.arrange({
        filter: filterValue,
      });

      $buttonGroup.find("a").removeClass("active");
      $this.addClass("active");
    });

    filterDropDownParent.on("change", function (event) {
      event.preventDefault();

      var filterGroup = $(event.target).attr("data-filter-group");

      filters[filterGroup] = event.target.value;

      // combine filters
      var filterValue = concatValues(filters);

      sortProducts.arrange({
        filter: filterValue,
      });
    });

    sortProducts.on("arrangeComplete", function (filteredItems) {
      if (filteredItems.length <= 0) {
        $(".no-results").removeClass("hide");
      } else {
        $(".no-results").addClass("hide");
      }
    });

    // Resize after a delay to wait for eqheights to complete
    $(window).on("load resize", function () {
      setTimeout(function () {
        sortProducts.arrange();
      }, 600);
    });
  }

  if ($("body").hasClass("home")) {
    var productTabs = $(".product-tabs");
    var tabButtonsParent = $(".product-tabs-ul");
    var filterDropDownParent = $(".products-filter-dropdown");
    var sortProducts_home = [];
    tabButtonsParent.on("click", ".filter-option", function (event) {
      event.preventDefault();

      var $this = $(event.currentTarget);

      // get group key
      var $buttonGroup = $this.parents(".product-tabs-ul");

      $buttonGroup.find("a").removeClass("active");
      $this.addClass("active");

      // Toggle tab panes
      productTabs.find(".tab-pane").removeClass("active");
      productTabs.find($this.attr("href")).addClass("active");

      // Resize cards
      if (typeof sortProducts_home[0] === "object") {
        for (var i = 0; i < sortProducts_home.length; i++) {
          sortProducts_home[i].layout();
        }
      }
    });

    filterDropDownParent.on("change", function (event) {
      event.preventDefault();

      var filterId = event.target.value;

      // Toggle tab panes
      productTabs.find(".tab-pane").removeClass("active");
      productTabs.find(filterId).addClass("active");

      // Resize cards
      if (typeof sortProducts_home[0] === "object") {
        for (var i = 0; i < sortProducts_home.length; i++) {
          sortProducts_home[i].layout();
        }
      }
    });

    // THE COMMENTED CODE IS USED FOR FILTERING BETWEEN TESTS & COURSES. DISABLED TEMPORARILY UNTIL MORE COURSES ARE ADDED
    // productTabs.find('.tab-pane').each(function(index, element) {
    //   var $element = $(element);

    //   var isoContainer = $element.find('.products-row .row');
    //   sortProducts_home[index] = new Isotope(isoContainer[0], {
    //     itemSelector: '.product',
    //     layoutMode: 'fitRows'
    //   });
    //   var filterButtonsParent = $element.find('.product-filters-ul');

    //   console.log(sortProducts_home[index])

    //   filterButtonsParent.on('click', '.filter-option', function(event) {
    //     event.preventDefault();

    //     var $this = $(event.currentTarget);

    //     // get group key
    //     var $buttonGroup = $this.parents('.product-filters-ul');

    //     sortProducts_home[index].arrange({
    //       filter: $this.attr('data-filter')
    //     });

    //     $buttonGroup.find('a').removeClass('active');
    //     $this.addClass('active');
    //   });

    //   sortProducts_home[index].on('arrangeComplete', function(filteredItems) {
    //     if (filteredItems.length <= 0) {
    //       $element.find('.no-results').removeClass('hide');
    //     } else {
    //       $element.find('.no-results').addClass('hide');
    //     }
    //   });

    // });
  }

  // $('#select-cat li a').click(function(){
  // 	$('#select-cat li a').removeClass('active');
  // 	$(this).addClass('active');
  // 	var selector = $(this).attr('data-filter');
  // 	isoContainer.isotope({ filter: selector });
  // 	return false;
  // });

  Array.prototype.clean = function (deleteValue) {
    for (var i = 0; i < this.length; i++) {
      if (this[i] == deleteValue) {
        this.splice(i, 1);
        i--;
      }
    }
    return this;
  };

  $(window).on("load", function () {
    if ($(".filter-option").length) {
      var hashID = window.location.hash.substring(1);
      var hashIDs = hashID.split(".").clean("");
      console.log(hashIDs);
      if (hashIDs.length) {
        hashIDs.forEach(function (el) {
          console.log($('.filter-option[data-filter=".' + el + '"]'));
          // $('.filter-option[data-filter=".'+el+'"]').parents('[data-filter-group]').find('.filter-option').removeClass('active');
          // $('.filter-option[data-filter=".'+el+'"]').addClass('active');
          $('.filter-option[data-filter=".' + el + '"]').trigger("click");
        });
        // isoContainer.isotope({
        //   filter: hashID
        // });
      }

      // if(hashID) {
      //     console.log($('.filter-option[data-filter="'+hashID+'"]'))
      // 	$('.filter-option').removeClass('active');
      // 	$('.filter-option[data-filter="'+hashID+'"]').addClass('active');
      // 	isoContainer.isotope({
      // 		filter: hashID
      // 	})
      //   }
    }
  });
});
